
import { SYSTEM_INFO_ENUM } from '@/constant/system-info';
import { useAccountStore } from '@/store/account';
import { reactive, ref, defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import http from '@/core/http';
import {
  DOMAIN_SYSTEM_ENUM,
} from 'cx-utils';

export default defineComponent({
  name: 'Login',
  components: {},
  setup() {
    const $router = useRouter();
    const $route = useRoute();
    const showPassword = ref(false);
    const jumpTargetPage = () => {
      const redirectUrl = $route.query.redirect
        ? decodeURIComponent($route.query.redirect as string)
        : '/resource-manage/domestic-goods/list';
      $router.replace(redirectUrl);
    };

    const accountStore = useAccountStore();
    const loading = ref(false);
    const rules = ref({
      username: {
        error: '',
        validator: (val: string) => {
          if (val === '') {
            rules.value.username.error = '手机号码不能为空';
          } else {
            rules.value.username.error = '';
          }
        },
      },
      password: {
        error: '',
        validator: (val: string) => {
          if (val === '') {
            rules.value.password.error = '密码不能为空';
          } else {
            rules.value.password.error = '';
          }
        },
      },
    });
    const form = reactive({
      username: '',
      password: '',
    });
    const validate = () => {
      rules.value.username.validator(form.username);
      rules.value.password.validator(form.password);
      if (rules.value.username.error || rules.value.password.error) {
        throw Error('校验失败');
      }
    };
    const userEl = ref<null|HTMLElement>(null);
    const passwordEl = ref<null|HTMLElement>(null);
    const handleUserInput = (e: any) => {
      if (e.target && e.target.value) {
        const val = e.target.value.trim();
        e.target.value = val;
        form.username = val;
      }
    };
    const handlePasswordInput = (e: any) => {
      if (e.target && e.target.value) {
        const val = e.target.value.trim();
        e.target.value = val;
        form.password = val;
      }
    };
    const handleChangeShowPassword = () => {
      showPassword.value = !showPassword.value;
    };
    const handleSubmit = async () => {
      try {
        validate();
        loading.value = true;
        const { username, password } = form;
        const data = {
          username,
          password,
        };
        await accountStore.loginBySSO(data);
        jumpTargetPage();
      } catch (e) {
        console.warn(e);
      } finally {
        loading.value = false;
      }
    };
    const handleUpdatePassword = () => {
      const url = http.systemDomainMap[DOMAIN_SYSTEM_ENUM.sso];
      window.location.href = `${url}/saas-sso/auth/modify/password`;
    };
    const handleResetPassword = () => {
      const url = http.systemDomainMap[DOMAIN_SYSTEM_ENUM.sso];
      window.location.href = `${url}/saas-sso/auth/forgot/password`;
    };
    return {
      handleUpdatePassword,
      handleResetPassword,
      handleChangeShowPassword,
      showPassword,
      handleUserInput,
      handlePasswordInput,
      userEl,
      passwordEl,
      handleSubmit,
      form,
      loading,
      copyright: SYSTEM_INFO_ENUM.COPYRIGHT,
      systemName: SYSTEM_INFO_ENUM.SYSTEM_NAME,
      systemEnglishName: SYSTEM_INFO_ENUM.SYSTEM_ENGLISH_NAME,
      rules,
    };
  },
});
